import React from 'react'

const About = () => {
  return (
    <div name ="About" className='w-full md:h-screen h-full bg-zinc-800 text-white py-10   md:py-1 '>
        <div className=' max-w-screen-lg flex flex-col mx-auto justify-center h-full w-full px-4'>
            <div>
                <h1 className='pb-6 text-4xl sm:text-5xl capitalize font-starwar '>About Me</h1>
            </div>
            <p className='text-lg my-2 md:text-xl'> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium molestias aut consequuntur minus voluptatum saepe dolores, recusandae fuga, perferendis rem pariatur id! Veniam aliquid neque, quae laudantium delectus molestias velit?</p>
             <br />
             <p className='text-lg my-2  md:text-xl'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse fugit aperiam dolorum, velit eos praesentium molestiae, doloremque ab nostrum dolore tempora non vitae hic eius cumque. Sed, sit! Repellendus, eaque. </p>
            <br />
            <p className='text-lg my-2 md:text-xl'> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit illo eum autem voluptatem sunt quia quis dolorum praesentium unde exercitationem? Mollitia in minima, labore nihil architecto illum culpa cupiditate deleniti! </p>
        </div>

    </div>
  )
}

export default About