import React ,{useState} from 'react'
import { motion } from 'framer-motion'

import delkolt from '../assets/projects/Delkolt.png'
 import simon from '../assets/projects/simon.png'
// import Codewiz from '..assets/projects/Codewiz'

const Projects = () => {
    const Proj= [{
        id:1,
        src: simon,
        title:'Simon Game',
    },{
        id:2,
        src: delkolt,
        title:'Delkolt website',
    }

]
  return (


    <div name= "projects" className='text-white  bg-zinc-800 w-full  min-h-screen py-10   '>
        <div className='max-w-screen-lg flex flex-col mx-auto justify-center h-full w-full px-4 '>
            <div className='pb-6'>
            <p className='text-xl'>Some of the stuff ive worked on</p>
                <h1 className='pb-6 text-3xl sm:text-5xl capitalize font-starwar '>Projects</h1>
            </div>
            {/* card div */}
          
                  
         

    </div>
    </div>
  )
}

export default Projects